
















































import { Vue, Component, Prop } from "vue-property-decorator"
import Offer from "~/models/Offer"

@Component({
  components: {},
})
export default class CounterOfferSummary extends Vue {
  @Prop({ required: true }) originalOffer!: Offer
  @Prop({ required: true }) newOffer!: Offer
}
