import { render, staticRenderFns } from "./OfferArchive.vue?vue&type=template&id=28f61f30&scoped=true&"
import script from "./OfferArchive.vue?vue&type=script&lang=ts&"
export * from "./OfferArchive.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f61f30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
