import { render, staticRenderFns } from "./UserSelectItem.vue?vue&type=template&id=cdab9306&scoped=true&"
import script from "./UserSelectItem.vue?vue&type=script&lang=ts&"
export * from "./UserSelectItem.vue?vue&type=script&lang=ts&"
import style0 from "./UserSelectItem.vue?vue&type=style&index=0&id=cdab9306&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdab9306",
  null
  
)

export default component.exports