




























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { state } from "@/store"

import House from "~/models/House"

import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import FileItem from "@/components/files/FileItem.vue"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import OfferActions from "@/components/offers/OfferActions.vue"
import OfferSummary from "@/components/offers/OfferSummary.vue"
import Offer from "~/models/Offer"
import EventBus from "~/utils/EventBus"
import OfferAmount from "@/components/offers/OfferAmount.vue"

@Component({
  components: {
    OcularIcon,
    OcularAvatar,
    HouseCard,
    OfferActions,
    FileItem,
    AgentItem,
    OfferSummary,
    OfferAmount,
  },
})
export default class OfferDetails extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: true }) loading!: boolean
  offer: Offer | null = null

  get offerId() {
    if (this.$route.params.offerId) return parseInt(this.$route.params.offerId)
    return null
  }

  get statusContainerStyle() {
    if (this.statusConfig) {
      return {
        background: `var(--${this.statusConfig.color})`,
        color:
          this.statusConfig.theme === "dark"
            ? "white !important"
            : "black !important",
      }
    }
    return {}
  }

  get statusConfig() {
    return this.offer?.statusConfig
  }

  get currentUser() {
    return state.profile || null
  }

  get userCanInteract() {
    return this.offer?.deal?.salesperson_ids?.includes(state.profile.id)
  }

  mounted() {
    this.initOffer()
  }

  @Watch("offerId")
  @Watch("house.offers")
  initOffer() {
    if (this.house?.offers && this.offerId && this.offerId >= 0) {
      let currentOfferIdx = this.house?.offers.findIndex(
        (houseOffer) => houseOffer.id == this.offerId
      )
      this.offer = this.house.offers[currentOfferIdx]
      if (this.offer && !this.offer?.house) {
        this.offer.house = this.house
      }
    }
    if (this.house?.visits) this.loading = false
  }

  offerUpdated(offer: Offer) {
    this.offer = offer
    this.$emit("refresh")
    EventBus.emit("dealOffersUpdated")
    this.initOffer()
  }
}
