





















import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"

@Component({
  components: {
    OcularDialog,
  },
})
export default class OfferCancel extends Vue {
  @Prop({ required: true }) offer!: Offer

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }
}
