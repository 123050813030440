

































































































































import { translate } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import OcularAvatar from "~/components/OcularAvatar.vue"
import Offer, { OfferRefusedReason } from "~/models/Offer"
import DealCustomer from "../deals/DealCustomer.vue"
import FileItem from "../files/FileItem.vue"

@Component({
  components: {
    AgentItem,
    FileItem,
    OcularAvatar,
    DealCustomer,
  },
})
export default class OfferSummary extends Vue {
  @Prop({ required: true }) offer!: Offer

  refusedReason(reason: OfferRefusedReason) {
    if (reason) return translate("refused_reason", reason)
    return null
  }
}
