




















































































import { state } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import Salespeople from "~/collections/Salespeople"
import UserSelect from "~/components/Inputs/UserSelect/UserSelect.vue"
import OcularDialog from "~/components/OcularDialog.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"
import Profile from "~/models/Profile"
import OfferSummary from "../OfferForm/OfferSummary.vue"

@Component({
  components: {
    OcularDialog,
    OfferSummary,
    UserSelect,
  },
})
export default class CreateGetAcceptDocument extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: true }) house!: House

  subject: string = this.defaultSubject
  message: string = this.defaultMessage
  loading = false
  ccm: Profile | null = null
  ccms = new Salespeople()

  /**
   * Hooks
   */
  mounted() {
    this.fetchGetAcceptProfiles()
  }

  /**
   * Computed props
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  get currentUser() {
    return state.profile
  }

  get defaultSubject() {
    return `IMPORTANT - Signature requise pour l’offre du bien situé ${this.house.address}`
  }

  get defaultMessage() {
    let message = `Bonjour ${this.offer.deal?.signatoriesNames},\n`
    message += `Nous sommes heureux que vous ayez eu un coup de cœur pour `
    message += `le bien situé ${this.house.address}. Voici l’offre `
    message += `que j'ai rédigé pour vous. Je vous prie de bien `
    message += `vouloir en prendre connaissance, puis la signer si elle vous convient.\n`
    message += `A bientôt !\n\n`
    message += `${this.currentUser.first_name}`
    return message
  }

  get formIsValid() {
    return this.message?.length > 0 && this.subject?.length > 0 && this.ccm
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  async fetchGetAcceptProfiles() {
    await this.ccms.fetchGaSalespeople()
  }

  close() {
    this.dialog.open = false
  }

  zoomOfferDoc() {
    if (this.offer.doc_drive_preview_url) {
      window.open(this.offer.doc_drive_preview_url, "_blank")
    }
  }

  async createGetAcceptDocument() {
    if (this.$route.params && this.ccm) {
      this.loading = true
      this.offer.house_id = parseInt(this.$route.params.houseId)
      const updatedOffer = await this.offer.createGetAcceptDocument(
        this.ccm.id,
        this.subject,
        this.message
      )
      this.$emit("updated", updatedOffer)
      this.loading = false
      this.close()
    }
  }

  /**
   * Rules
   */
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}
