



































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"

@Component({
  components: {
    OcularDialog,
  },
})
export default class OfferImport extends Vue {
  @Prop({ required: true }) offer!: Offer
  fileToImport: File | null = null

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  async importSignedOffer() {
    if (this.fileToImport) {
      const updatedOffer = await this.offer.importOfferFile(
        this.fileToImport,
        "signed_offer"
      )
      this.$emit("updated", updatedOffer)
      this.close()
    }
  }
  cancel() {
    this.close()
  }

  /**
   * Rules
   */
  notEmptyFile = (file: File | Array<File> | null) => !!file || "Champ requis"

  isPDFfile = (file: File | null) => {
    const pattern = /\.pdf$/
    return (file && pattern.test(file.name)) || "Doit être un fichier .pdf"
  }
}
