





























































import { Component, Vue, Prop } from "vue-property-decorator"
import { translate } from "@/store"
import { DealDocumentNature } from "~/models/DealDocument"

@Component({
  components: {},
})
export default class EmailSummary extends Vue {
  @Prop({ required: true }) email!: any

  humanizeDocumentNature(nature: DealDocumentNature) {
    return translate("document_nature", nature)
  }
}
