

























































































import { state } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"
import OfferSummary from "../OfferForm/OfferSummary.vue"

@Component({
  components: {
    OcularDialog,
    OfferSummary,
  },
})
export default class OfferSendCustomerEmail extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: true }) house!: House

  subject: string = this.defaultSubject
  message: string = this.defaultMessage
  loading = false

  /**
   * Computed props
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  get currentUser() {
    return state.profile
  }

  get defaultSubject() {
    return `IMPORTANT - Signature requise pour l’offre du bien situé ${this.house.address}`
  }

  get defaultMessage() {
    let message = `Bonjour ${this.offer.deal?.signatoriesNames},\n`
    message += `Nous sommes heureux que vous ayez eu un coup de cœur pour `
    message += `le bien situé ${this.house.address}. Veuillez trouver en pièce `
    ;(message += `jointe de ce mail, l'offre rédigée. Je vous prie de bien `),
      (message += `vouloir en prendre connaissance, puis la signer si elle `)
    message += `vous convient.\n`
    message += `A bientôt !\n\n`
    message += `${this.currentUser.first_name}`
    return message
  }

  get formIsValid() {
    return this.message?.length > 0 && this.subject?.length > 0
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  zoomOfferDoc() {
    if (this.offer.doc_drive_preview_url) {
      window.open(this.offer.doc_drive_preview_url, "_blank")
    }
  }

  async sendOfferToSignToCustomer(send = false) {
    if (this.$route.params) {
      this.loading = true
      this.offer.house_id = this.house.id
      const updatedOffer = await this.offer.sendOfferToSignToCustomer(
        this.subject,
        this.message,
        send
      )
      this.$emit("updated", updatedOffer)
      this.loading = false
    }
  }

  async createDraft() {
    await this.sendOfferToSignToCustomer()
    this.close()
  }

  async sendOfferToSign() {
    await this.sendOfferToSignToCustomer(true)
    this.close()
  }

  /**
   * Rules
   */
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}
