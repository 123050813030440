





































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"
import snackbar from "~/plugins/snackbar"
import CounterOfferSummary from "./CounterOfferSummary.vue"

@Component({
  components: {
    OcularDialog,
    CounterOfferSummary,
  },
})
export default class CounterOfferForm extends Vue {
  @Prop({ required: true }) originalOffer!: Offer
  @Prop({ required: true }) house!: House
  offer = new Offer()
  offerIncludesSuspensiveConditions = false
  createdAt: string | null = null
  endValidityAt: string | null = null
  loader = false

  /**
   * Computed props
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  get formattedHousePrice() {
    if (this.house.price)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        this.house.price
      )
    return ""
  }

  get formattedAmount() {
    if (this.offer.amount)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        this.offer.amount
      )
    return ""
  }
  set formattedAmount(value) {
    this.offer.amount = parseInt(value.replace(/\s+|[a-z]+/g, ""), 10)
  }

  /**
   * Hooks
   */
  mounted() {
    this.offer = new Offer(this.originalOffer)
    this.offer.amount = this.house.price
    this.offer.created_at = new Date()
    this.createdAt = this.offer.created_at?.toISOString().split("T")[0] || null
    const endValidity = new Date()
    endValidity.setDate(endValidity.getDate() + 7)
    this.offer.end_validity_at = endValidity
    this.endValidityAt =
      this.offer.end_validity_at?.toISOString().split("T")[0] || null
  }

  @Watch("createdAt")
  onCreatedAtChange() {
    if (this.createdAt && this.createdAt !== "") {
      this.offer.created_at = new Date(this.createdAt)
    } else {
      this.offer.created_at = null
    }
  }

  @Watch("endValidityAt")
  onEndValidityAtChange() {
    if (this.endValidityAt && this.endValidityAt !== "") {
      this.offer.end_validity_at = this.endValidityAt
        ? new Date(this.endValidityAt)
        : null
    } else {
      this.offer.end_validity_at = null
    }
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  async saveCounterOffer() {
    if (this.$route.name) {
      const urlComponents = this.$route.name.split("/")
      const houseId = this.$route.params.houseId

      this.loader = true
      this.originalOffer.house_id = parseInt(houseId)
      await this.originalOffer.createCounterOffer(this.offer)
      snackbar({
        color: "success",
        content: "La contre-offre a bien été créée",
      })
      this.$emit("created")
      this.loader = false
      this.close()
      if (this.$route.name) {
        this.$router.push({
          name: `${urlComponents[0]}/house/offers`,
          params: {
            houseId: houseId.toString(),
          },
        })
      }
    }
  }

  cancel() {
    this.close()
  }

  // Form rules
  notEmptyNumber = (value: number | Array<any> | null) => {
    return !!(value && value != 0) || "Champ requis"
  }
  notEmpty = (value: string | Array<any> | null) =>
    !!(value && value.length) || "Champ requis"
}
