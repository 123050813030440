














import { translate } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import DealDocument from "~/models/DealDocument"

@Component({
  components: {},
})
export default class FileItem extends Vue {
  @Prop({ required: false, default: "mdi-file-outline" }) icon!: string
  @Prop({ required: true }) document!: DealDocument

  get documentNature() {
    if (this.document.nature)
      return translate("document_nature", this.document.nature)
    return "Inconnu"
  }
}
