






























import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"
import AgentInteractionRating from "@/components/agents/AgentInteractionRating.vue"

@Component({
  components: {
    OcularDialog,
    AgentInteractionRating,
  },
})
export default class OfferArchive extends Vue {
  @Prop({ required: true }) offer!: Offer
  interactionRating: number | null = null
  loading = false

  /**
   * Computed props
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  cancel() {
    this.close()
  }

  rateChanged(value: number | null) {
    this.interactionRating = value
  }

  async archive() {
    if (this.offer) {
      this.loading = true
      this.offer.status = "archived"
      const updatedOffer = await this.offer.save()
      this.offer.assignReceivedData(updatedOffer)
      this.$emit("updated", this.offer)
      this.loading = false
      this.close()
    }
  }

  /**
   * Rules
   */
  notEmpty = (value: string | null | undefined) =>
    !!(value && value.length) || "Champ requis"
}
