import Collection from "../jrdm-mc/Collection"
import Company from "../models/Company"

export default class Companies extends Collection<Company> {
  static responseField = "companies"
  static route = "companies"

  model() {
    return Company
  }

  get workItems() {
    return this.items.filter((item) => item.category === "work")
  }
  get notaryItems() {
    return this.items.filter((item) => item.category === "notary")
  }
}
