
















import { Component, Vue, Prop } from "vue-property-decorator"
import { themeColor } from "~/styles/colors"

import OcularIcon from "~/components/OcularIcon.vue"

@Component({
  components: {
    OcularIcon,
  },
})
export default class OfferActionItem extends Vue {
  @Prop({ required: false, default: "blue" }) color!: string
  @Prop({ required: false, default: "rgba(88, 157, 254, .5)" }) bgColor!: string
  @Prop({ required: false, default: "0px" }) border!: string
  @Prop({ required: true }) icon!: string
  @Prop({ required: true }) title!: string
  @Prop({ required: false }) description!: string

  get iconContainerStyle() {
    return {
      background: themeColor(this.bgColor),
      border: this.border,
    }
  }
}
