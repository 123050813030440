

















































































import { state } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import House from "~/models/House"
import Offer, { OfferEmail, OfferEmailContact } from "~/models/Offer"
import EmailSummary from "./EmailSummary.vue"

@Component({
  components: {
    OcularDialog,
    EmailSummary,
  },
})
export default class OfferSendSeller extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: true }) house!: House
  loading = false
  includeBuyerEmail = false
  subject = this.defaultSubject
  message = this.defaultMessage

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  get currentUser() {
    return state.profile
  }

  get formIsValid() {
    return this.message && this.subject
  }

  get emailCc() {
    const cc: OfferEmailContact[] = []
    if (this.includeBuyerEmail && this.offer.deal?.signatories) {
      cc.push({
        name: `${this.offer.deal?.signatories
          .map((signatory) => signatory.name)
          .join(", ")}`,
        email: `${this.offer.deal?.signatories
          .map((signatory) => signatory.email)
          .join(", ")}`,
      })
    }
    return cc
  }

  get defaultSubject() {
    let subject = this.offer.isAtPrice ? "[OFFRE AU PRIX]" : "[OFFRE]"
    subject += ` ${this.house.address}`
    return subject
  }

  get defaultMessage() {
    let message = `Bonjour`
    if (this.offer.agent) message += ` ${this.offer.agent.fullname}`
    else if (this.offer.seller_full_name)
      message += ` ${this.offer.seller_full_name}`
    message += `,\n\n`
    message += "J'ai le plaisir de vous faire parvenir une offre pour "
    message +=
      (this.offer.multipleSignatories ? "mes clients" : "mon client") + " "
    message += `concernant le bien situé ${this.house.address}.\n\n`
    message += "En vous souhaitant une bonne réception.\n"
    message += `${this.currentUser.first_name} ${this.currentUser.last_name}`
    return message
  }

  get emailTo() {
    const to: OfferEmailContact[] = []
    if (this.offer.seller_type === "agency" && this.offer.seller_email) {
      to.push({
        name: this.offer.seller_full_name || this.offer.agent?.fullname || "",
        email: this.offer.seller_email,
      })
    } else if (this.offer.seller_full_name && this.offer.seller_email) {
      to.push({
        name: this.offer.seller_full_name,
        email: this.offer.seller_email,
      })
    }
    return to
  }

  get email(): OfferEmail {
    return {
      from: {
        name: this.offer.owner?.fullname || "",
        email: this.offer.owner?.email || "",
      },
      cc: this.emailCc,
      to: this.emailTo,
      subject: this.subject,
      message: this.message,
      documents: this.offer.deal_documents,
    }
  }

  open() {
    this.dialog.open = true
  }
  close() {
    this.dialog.open = false
  }
  cancel() {
    this.close()
  }
  onCreateDraftPress() {
    this.prepareSellerEmail(false)
    this.close()
  }
  onSendEmailPress() {
    this.prepareSellerEmail(true)
    this.close()
  }
  async prepareSellerEmail(shouldSend: boolean) {
    if (this.$route.params && this.offer.seller_email) {
      this.loading = true
      this.offer.house_id = parseInt(this.$route.params.houseId)
      const updatedOffer = await this.offer.emailToSeller(
        this.offer.seller_email,
        this.subject,
        this.message,
        this.includeBuyerEmail,
        shouldSend
      )
      this.$emit("updated", updatedOffer)
      this.loading = false
      this.close()
    }
  }

  // Form rules
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}
