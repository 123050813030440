






























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Offer from "~/models/Offer"
import AgentInteractionRating from "@/components/agents/AgentInteractionRating.vue"
import House from "~/models/House"
import Companies from "~/collections/Companies"

@Component({
  components: {
    OcularDialog,
    AgentInteractionRating,
  },
})
export default class OfferAcceptBelled extends Vue {
  @Prop({ required: true }) offer!: Offer | null
  @Prop({ required: true }) house!: House | null
  form = this.offer
  workCompanies = new Companies()
  saleAgreementSignatureAt: string | null = null
  authenticActSignatureAt: string | null =
    this.form?.authentic_act_signature_at?.toISOString().split("T")[0] || null
  realAuthenticActSignatureAt: string | null =
    this.form?.real_authentic_act_signature_at?.toISOString().split("T")[0] ||
    null
  notaryCompanyId: number | null = null
  workCompanyId: number | null = null
  interactionRating: number | null = null
  feesItems = [
    {
      text: "1%",
      value: 1,
    },
    {
      text: "1,5%",
      value: 1.5,
    },
    {
      text: "2%",
      value: 2,
    },
    {
      text: "2,3%",
      value: 2.3,
    },
    {
      text: "2,7%",
      value: 2.7,
    },
    {
      text: "2,9%",
      value: 2.9,
    },
  ]

  /* Hooks */
  mounted() {
    this.workCompanies.fetch()
    this.authenticActSignatureAt =
      this.form?.authentic_act_signature_at?.toISOString().split("T")[0] || null
    this.realAuthenticActSignatureAt =
      this.form?.real_authentic_act_signature_at?.toISOString().split("T")[0] ||
      null
    this.saleAgreementSignatureAt =
      this.form?.sale_agreement_signature_at?.toISOString().split("T")[0] ||
      null
    const workCompany = this.form?.companies.filter(
      (company) => company.category === "work"
    )
    const notaryCompany = this.form?.companies.filter(
      (company) => company.category === "notary"
    )
    if (workCompany) this.workCompanyId = workCompany[0]?.id
    if (notaryCompany) this.notaryCompanyId = notaryCompany[0]?.id
  }

  @Watch("saleAgreementSignatureAt")
  onSaleAgreementSignatureAtChange() {
    if (this.form) {
      if (this.saleAgreementSignatureAt) {
        const day = parseInt(this.saleAgreementSignatureAt.split("-")[2])
        const mounth = parseInt(this.saleAgreementSignatureAt.split("-")[1])
        const year = parseInt(this.saleAgreementSignatureAt.split("-")[0])
        this.form.sale_agreement_signature_at = new Date()
        this.form.sale_agreement_signature_at?.setDate(day)
        this.form.sale_agreement_signature_at?.setMonth(mounth - 1)
        this.form.sale_agreement_signature_at?.setFullYear(year)
        this.form.sale_agreement_signature_at?.setHours(0)
        this.form.sale_agreement_signature_at?.setMinutes(0)
        this.form.sale_agreement_signature_at?.setSeconds(0)
        this.form.sale_agreement_signature_at?.setMilliseconds(0)
      } else {
        this.form.sale_agreement_signature_at = null
      }
    }
  }

  @Watch("authenticActSignatureAt")
  onAuthenticActSignatureAtChange() {
    if (this.form) {
      if (this.authenticActSignatureAt) {
        const day = parseInt(this.authenticActSignatureAt.split("-")[2])
        const mounth = parseInt(this.authenticActSignatureAt.split("-")[1])
        const year = parseInt(this.authenticActSignatureAt.split("-")[0])
        this.form.authentic_act_signature_at = new Date()
        this.form.authentic_act_signature_at?.setDate(day)
        this.form.authentic_act_signature_at?.setMonth(mounth - 1)
        this.form.authentic_act_signature_at?.setFullYear(year)
        this.form.authentic_act_signature_at?.setHours(0)
        this.form.authentic_act_signature_at?.setMinutes(0)
        this.form.authentic_act_signature_at?.setSeconds(0)
        this.form.authentic_act_signature_at?.setMilliseconds(0)
      } else {
        this.form.authentic_act_signature_at = null
      }
    }
  }

  /**
   * Computed
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  /**
   * Methods
   */
  rateChanged(value: number | null) {
    this.interactionRating = value
  }

  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }
  cancel() {
    this.close()
  }

  async ringTheBell() {
    if (this.form) {
      this.form.status = "accepted_belled"
      this.form.rating = this.interactionRating
      this.form.company_ids = []
      if (this.workCompanyId) this.form.company_ids.push(this.workCompanyId)
      if (this.notaryCompanyId) this.form.company_ids.push(this.notaryCompanyId)
      const offerUpdated = await this.form.save()
      this.$emit("updated", offerUpdated)
    }
  }

  /* Rules */
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}
