















































































































import { Component, Vue, Prop } from "vue-property-decorator"
import Offer from "~/models/Offer"
import OfferActionItem from "./OfferActionItem.vue"
import OfferFormModale from "./OfferForm/OfferFormModale.vue"
import OfferImport from "./OfferImport/OfferImport.vue"
import CreateGetAcceptDocument from "./CreateGetAcceptDocument/CreateGetAcceptDocument.vue"
import CounterOfferForm from "./CounterOfferForm/CounterOfferForm.vue"
import OcularConfirm from "~/components/OcularConfirm.vue"
import OfferSendSeller from "./OfferSendSeller/OfferSendSeller.vue"
import OfferSendCustomerEmail from "./OfferSendCustomerEmail/OfferSendCustomerEmail.vue"
import OfferRefuse from "./OfferRefuse/OfferRefuse.vue"
import OfferRetract from "./OfferRetract/OfferRetract.vue"
import OfferAccept from "./OfferAccept/OfferAccept.vue"
import House from "~/models/House"
import OfferAcceptBelled from "./OfferAcceptBelled/OfferAcceptBelled.vue"
import OfferCustomizer from "./OfferCustomizer/OfferCustomizer.vue"
import OfferArchive from "./OfferArchive/OfferArchive.vue"
import OfferDatesEditor from "./OfferDatesEditor/OfferDatesEditor.vue"

@Component({
  components: {
    OcularConfirm,
    OfferActionItem,
    OfferFormModale,
    CreateGetAcceptDocument,
    OfferSendSeller,
    OfferImport,
    OfferRefuse,
    OfferAccept,
    OfferAcceptBelled,
    OfferRetract,
    CounterOfferForm,
    OfferSendCustomerEmail,
    OfferCustomizer,
    OfferArchive,
    OfferDatesEditor,
  },
})
export default class OfferActions extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: true }) house!: House

  get actionsByStatus() {
    return {
      draft: [
        this.allActions.customize,
        this.allActions.create_get_accept_document,
        this.allActions.send_customer,
        this.allActions.download_offer_to_sign,
        this.allActions.edit,
        this.allActions.archive,
      ],
      pending_get_accept: [
        this.allActions.open_on_getaccept,
        // this.allActions.send_get_accept_document,
        this.allActions.edit,
        this.allActions.archive,
      ],
      signing: [
        this.allActions.upload_signed,
        this.allActions.edit,
        this.allActions.archive,
      ],
      pending_send: [
        this.allActions.send,
        this.allActions.sent_manually,
        this.allActions.download_signed_offer,
        this.allActions.archive,
      ],
      sent: [
        this.allActions.accept,
        this.allActions.refuse,
        this.allActions.download_signed_offer,
        this.allActions.retract,
      ],
      accepted: [
        this.allActions.ring_the_bell,
        this.allActions.refuse,
        this.allActions.download_signed_offer,
        this.allActions.retract,
      ],
      accepted_belled: [
        this.allActions.update_dates,
        this.allActions.refuse,
        this.allActions.accept,
        this.allActions.download_signed_offer,
        this.allActions.retract,
      ],
      refused: [
        this.allActions.accept,
        this.allActions.download_signed_offer,
        this.allActions.counter_offer,
      ],
      retracted: [
        this.allActions.counter_offer,
        this.allActions.download_signed_offer,
      ],
      archived: [this.allActions.download_offer_to_sign],
    }
  }

  get allActions() {
    return {
      edit: {
        title: "Modifier",
        id: "edit",
        description: "Modifier les informations de l’offre",
        icon: "mdi-pencil-outline",
        color: "blue",
        action: () => {
          const houseOfferEditor = this.$refs
            .houseOfferEditor as OfferFormModale
          houseOfferEditor.open()
        },
      },
      customize: {
        title: "Personnaliser",
        id: "customize",
        description: "Reformuler et mettre en page",
        icon: "mdi-view-dashboard-edit-outline",
        color: "blue",
        action: () => {
          const offerCustomizor = this.$refs.offerCustomizer as OfferCustomizer
          offerCustomizor.open()
        },
      },
      download_offer_to_sign: {
        id: "download_offer_to_sign",
        icon: "mdi-download-outline",
        title: "Télécharger",
        description: "Télécharger l'offre à signer",
        color: "blue",
        action: () => this.downloadOffer(),
      },
      send_customer: {
        title: "E-mail",
        id: "send_customer",
        description: "Envoi au client pour signature manuelle",
        icon: "mdi-email-outline",
        color: "#00c196",
        bgColor: "rgba(0, 193, 150, .5)",
        action: () => {
          const offerSendCustomerEmail = this.$refs
            .offerSendCustomerEmail as OfferSendCustomerEmail
          offerSendCustomerEmail.open()
        },
      },
      create_get_accept_document: {
        title: "GetAccept",
        id: "create_get_accept_document",
        description: "Envoi au client pour signature électronique",
        icon: "mdi-file-sign",
        color: "#00c196",
        bgColor: "rgba(0, 193, 150, .5)",
        action: () => {
          const createGetAcceptDocument = this.$refs
            .createGetAcceptDocument as CreateGetAcceptDocument
          createGetAcceptDocument.open()
        },
      },
      open_on_getaccept: {
        title: "Continuer sur GetAccept",
        id: "open_on_getaccept",
        description:
          "Configurer des zones de signatures et envoyer le document",
        icon: "mdi-text-box-edit-outline",
        color: "blue",
        action: () => {
          window.open(
            `https://app.getaccept.com/document/edit/${this.offer.get_accept_document_id}`
          )
        },
      },
      send_get_accept_document: {
        title: "Envoyer",
        id: "send_get_accept_document",
        description: "Demander la signature au client",
        icon: "mdi-email-fast-outline",
        color: "#00c196",
        bgColor: "rgba(0, 193, 150, .5)",
        action: () => {
          const confirmModale = this.$refs
            .sendGetAcceptDocumentConfirm as OcularConfirm
          confirmModale.open()
        },
      },
      retract: {
        title: "Se rétracter",
        id: "retract",
        description: "Renoncer à l’offre",
        icon: "mdi-delete-outline",
        color: "text",
        bgColor: "rgba(58, 60, 70, .5)",
        action: () => {
          const offerRetractModale = this.$refs.offerRetract as OfferRetract
          offerRetractModale.open()
        },
      },
      archive: {
        title: "Archiver",
        id: "archive",
        description: "Archiver l’offre",
        icon: "mdi-delete-outline",
        color: "text",
        bgColor: "rgba(58, 60, 70, .5)",
        action: () => {
          const offerArchiveModale = this.$refs.offerArchive as OfferArchive
          offerArchiveModale.open()
        },
      },
      upload_signed: {
        title: "Importer",
        id: "upload_signed",
        description: "Importer l'offre signée si la signature est manuelle",
        icon: "mdi-upload-outline",
        color: "blue",
        action: () => {
          const offerImportModale = this.$refs.offerImport as OfferImport
          offerImportModale.open()
        },
      },
      download_signed_offer: {
        title: "Télécharger",
        id: "download_signed_offer",
        icon: "mdi-download-outline",
        description: "Téléchargez une copie de l’offre signée",
        color: "blue",
        action: async () => {
          this.offer.house_id = parseInt(this.$route.params.houseId)
          const data = await this.offer.downloadSignedOffer()
          const downloadUrl = data.download_url
          window.open(downloadUrl, "_blank")
        },
      },
      send: {
        title: "Envoyer",
        id: "send",
        description: "Proposer au vendeur",
        icon: "mdi-email-fast-outline",
        color: "#00c196",
        bgColor: "rgba(0, 193, 150, .5)",
        action: () => {
          const OfferSendSellerModale = this.$refs
            .offerSendSeller as OfferSendSeller
          OfferSendSellerModale.open()
        },
      },
      sent_manually: {
        title: "Déjà envoyée",
        id: "sent_manually",
        description: "Vous avez déjà envoyé votre offre ?",
        icon: "mdi-email-check-outline",
        color: "#00c196",
        bgColor: "rgba(0, 193, 150, .5)",
        action: async () => {
          this.offer.status = "sent"
          const offer = await this.offer.save()
          this.offerUpdated(offer)
        },
      },
      accept: {
        title: "Offre acceptée",
        id: "accept",
        description: "Le vendeur a accepté l'offre",
        icon: "mdi-check",
        color: "success",
        bgColor: "rgba(87, 217, 163, .5)",
        action: () => {
          const OfferAcceptModale = this.$refs.offerAccept as OfferAccept
          OfferAcceptModale.open()
        },
      },
      ring_the_bell: {
        title: "Clocher",
        id: "ring_the_bell",
        description: "Sonner l'offre clochée !",
        icon: "mdi-party-popper",
        color: "success",
        bgColor: "rgba(87, 217, 163, .5)",
        border: "3px solid var(--success)",
        action: () => {
          const OfferAcceptBelledModale = this.$refs
            .offerAcceptBelled as OfferAcceptBelled
          OfferAcceptBelledModale.open()
        },
      },
      refuse: {
        title: "Offre refusée",
        id: "refuse",
        description: "Le vendeur a refusé l'offre",
        icon: "mdi-cancel",
        color: "#F44A4A",
        bgColor: "rgba(244, 74, 74, .5)",
        action: () => {
          const offerRefuseModale = this.$refs.offerRefuse as OfferRefuse
          offerRefuseModale.open()
        },
      },
      counter_offer: {
        title: "Contre-offre",
        id: "counter_offer",
        description: "Créer une contre-offre",
        icon: "mdi-plus-circle-outline",
        color: "blue",
        action: () => {
          const counterOfferFormModale = this.$refs
            .counterOfferForm as CounterOfferForm
          counterOfferFormModale.open()
        },
      },
      update_dates: {
        title: "Ajuster les dates",
        id: "update_dates",
        description: "Modifier les dates prévues ou réelles des signatures",
        icon: "mdi-calendar-outline",
        color: "blue",
        action: () => {
          const offerDatesEditor = this.$refs
            .offerDatesEditor as OfferDatesEditor
          offerDatesEditor.open()
        },
      },
    }
  }

  get actions() {
    if (this.offer.status) {
      return this.actionsByStatus[this.offer.status]
    }
    return null
  }

  downloadOffer() {
    if (this.offer.doc_drive_download_url) {
      window.open(this.offer.doc_drive_download_url, "_blank")
    }
  }

  offerUpdated(offer: Offer) {
    this.$emit("offer-updated", offer)
  }

  redirectToHouseOffers() {
    const dealId = this.$route.params.dealId
    const houseId = this.$route.params.houseId
    this.offerUpdated(this.offer)
    this.$router.push({
      path: `/deals/${dealId}/under-offer/house/${houseId}/offers`,
    })
  }

  offerRetracted() {
    this.redirectToHouseOffers()
  }

  offerArchived() {
    this.redirectToHouseOffers()
  }

  actionIsAvailable(actionId: string) {
    return this.actions && this.actions.find((action) => action.id === actionId)
  }

  async sendGetAcceptDocument() {
    await this.offer.sendGetAcceptDocument()
    this.offerUpdated(this.offer)
  }
}
