

























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"

@Component({
  components: {
    OcularDialog,
  },
})
export default class OfferDatesEditor extends Vue {
  @Prop({ required: true }) offer!: Offer | null
  @Prop({ required: true }) house!: House | null
  form = new Offer().assign(this.offer)
  saleAgreementSignatureAt: string | null = null
  authenticActSignatureAt: string | null =
    this.form?.authentic_act_signature_at?.toISOString().split("T")[0] || null
  realAuthenticActSignatureAt: string | null =
    this.form?.real_authentic_act_signature_at?.toISOString().split("T")[0] ||
    null

  /* Hooks */
  mounted() {
    this.authenticActSignatureAt =
      this.form?.authentic_act_signature_at?.toISOString().split("T")[0] || null
    this.realAuthenticActSignatureAt =
      this.form?.real_authentic_act_signature_at?.toISOString().split("T")[0] ||
      null
    this.saleAgreementSignatureAt =
      this.form?.sale_agreement_signature_at?.toISOString().split("T")[0] ||
      null
  }

  @Watch("saleAgreementSignatureAt")
  onSaleAgreementSignatureAtChange() {
    if (this.form) {
      if (this.saleAgreementSignatureAt) {
        const day = parseInt(this.saleAgreementSignatureAt.split("-")[2])
        const mounth = parseInt(this.saleAgreementSignatureAt.split("-")[1])
        const year = parseInt(this.saleAgreementSignatureAt.split("-")[0])
        this.form.sale_agreement_signature_at = new Date()
        this.form.sale_agreement_signature_at?.setDate(day)
        this.form.sale_agreement_signature_at?.setMonth(mounth - 1)
        this.form.sale_agreement_signature_at?.setFullYear(year)
        this.form.sale_agreement_signature_at?.setHours(0)
        this.form.sale_agreement_signature_at?.setMinutes(0)
        this.form.sale_agreement_signature_at?.setSeconds(0)
        this.form.sale_agreement_signature_at?.setMilliseconds(0)
      } else {
        this.form.sale_agreement_signature_at = null
      }
    }
  }

  @Watch("authenticActSignatureAt")
  onAuthenticActSignatureAtChange() {
    if (this.form) {
      if (this.authenticActSignatureAt) {
        const day = parseInt(this.authenticActSignatureAt.split("-")[2])
        const mounth = parseInt(this.authenticActSignatureAt.split("-")[1])
        const year = parseInt(this.authenticActSignatureAt.split("-")[0])
        this.form.authentic_act_signature_at = new Date()
        this.form.authentic_act_signature_at?.setDate(day)
        this.form.authentic_act_signature_at?.setMonth(mounth - 1)
        this.form.authentic_act_signature_at?.setFullYear(year)
        this.form.authentic_act_signature_at?.setHours(0)
        this.form.authentic_act_signature_at?.setMinutes(0)
        this.form.authentic_act_signature_at?.setSeconds(0)
        this.form.authentic_act_signature_at?.setMilliseconds(0)
      } else {
        this.form.authentic_act_signature_at = null
      }
    }
  }

  @Watch("realAuthenticActSignatureAt")
  onRealAuthenticActSignatureAtChange() {
    if (this.form) {
      if (this.realAuthenticActSignatureAt) {
        const day = parseInt(this.realAuthenticActSignatureAt.split("-")[2])
        const mounth = parseInt(this.realAuthenticActSignatureAt.split("-")[1])
        const year = parseInt(this.realAuthenticActSignatureAt.split("-")[0])
        this.form.real_authentic_act_signature_at = new Date()
        this.form.real_authentic_act_signature_at?.setDate(day)
        this.form.real_authentic_act_signature_at?.setMonth(mounth - 1)
        this.form.real_authentic_act_signature_at?.setFullYear(year)
        this.form.real_authentic_act_signature_at?.setHours(0)
        this.form.real_authentic_act_signature_at?.setMinutes(0)
        this.form.real_authentic_act_signature_at?.setSeconds(0)
        this.form.real_authentic_act_signature_at?.setMilliseconds(0)
      } else {
        this.form.real_authentic_act_signature_at = null
      }
    }
  }

  /**
   * Computed
   */
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }
  cancel() {
    this.close()
  }

  async updateDates() {
    if (this.form) {
      const offerUpdated = await this.form.save()
      this.$emit("updated", offerUpdated)
    }
  }

  /* Rules */
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
}
