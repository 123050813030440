







import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import Profile from "~/models/Profile"

@Component({
  components: {
    OcularAvatar,
  },
})
export default class UserSelect extends Vue {
  @Prop({ required: true }) item!: Profile
}
