






















import { Component, Vue, Prop } from "vue-property-decorator"
import Profile from "~/models/Profile"
import UserSelectItem from "./UserSelectItem.vue"

@Component({
  components: {
    UserSelectItem,
  },
})
export default class UserSelect extends Vue {
  @Prop({ required: false }) rules!: any[]
  @Prop({ required: true }) items!: Profile[]
  @Prop({ required: false, default: "Séléctionner un profil" })
  placeholder!: string
  selectedUser: Profile | null = null

  onUserSelected(user: Profile | null | undefined) {
    if (!user) this.$emit("input", null)
    else this.$emit("input", user)
  }
}
